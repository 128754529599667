.App {
  text-align: center;
}

[data-amplify-authenticator] {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.amplify-input amplify-field-group__control {
  height: 100%;
}

#radix-\:r0\:-content-0 {
  padding: 4vw;
}

[data-amplify-authenticator] input {
  border: 1px solid #ccc;
}

[data-amplify-authenticator] button {
  background-color: #28a490;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none; 
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; 
}
