/* Modal.css */
.DataFilterModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.DataFilterModal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  height: 87vh;
  width: 90vw;
  overflow: hidden;
  margin-top: 10vh;
}

.DataFilterModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DataFilterCloseButton {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.DataFilterModalContent {
  display: flex;
}
