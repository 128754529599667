.ProgressBarContainer {
  overflow: hidden;
  width: 70%;
}

.ProgressBar {
  color: white;
  text-align: center;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1vw;
  padding-right: 1vw;
}
