

.SimpleDataListHeader {
  width: 100%;
  padding: 15px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
  font-weight: 600;
}

.SimpleDataListContainer {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.SimpleDataItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.SimpleDataItemContent {
  flex-grow: 1;
}

.FilterModal{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  max-height: 20vh;
  overflow: unset;
}