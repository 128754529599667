.DataListMain {
  background-color: white;
  flex-direction: column;
  width: 65vw;
  overflow: hidden;
  flex-grow: 1;
  padding-bottom: 10vh;
}

.TagsContainerDataList {
  padding: 10px;
  margin-top: 1vh;
  line-height: 3vh;
}

.DataListHeader {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.DataListContainerMain {
  display: flex;
  height: 100%;
}

.DataItemContainerMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  overflow: hidden;
}

.DataItemContentMain {
  flex-direction: column;
  flex: 1;
  width: 30vw;
  overflow: auto;
  height: 100%;
}

.Tag {
  position: relative;
  padding: 5px;
  margin-right: 5px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 1vh;
  z-index: 0;
  overflow: visible;
  cursor: pointer;
  transition: background-color 0.2s;
  z-index: 999999;
}

.Tag:hover {
  background-color: #f0f0f0;
}

.CatalogTooltip {
  display: none;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5e5c5c;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 9999;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
}

.Tag:hover .CatalogTooltip {
  display: block;
  opacity: 1;
}
.TagsContainer {
  margin-left: 1vw;
  flex-grow: 1;
  flex: 1;
  max-width: calc(70vw - 150px);
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  overflow: visible;
  height: 80%;
  padding-top: 0.5vh;
}
.TagsDataName {
  font-size: 16px;
  font-weight: 500;
  overflow: auto;
  max-width: 50vw;
  white-space: nowrap;
  min-height: 2vh;
}

.DataGroupButtonContainer {
  justify-content: flex-end;
  width: 50vw;
  align-items: flex-end;
  text-align: right;
  display: flex;
  padding-top: 1vh;
  position: absolute;
  bottom: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.DataGroupButton {
  display: flex;
  padding: 10px;
  margin-right: 2vw;
  margin-bottom: 1vh;
  align-self: flex-end;
  cursor: pointer;
  color: white;
  font-weight: 700;
  border-radius: 5px;
}

.previewButtonDataList {
  opacity: 0.8;
  color: white;
  font-weight: 600;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-left: 5px;
  border-radius: 4px;
}

.previewButton:hover {
  background-color: #30917a;
}

.previewButton:focus {
  outline: none;
}

.previewButton svg {
  font-size: 20px;
  color: white;
}

.SelectionCountText {
  color: white;
  display: flex;
  flex-direction: row;
}
.SelectionCountText > button:first-child {
  margin-right: 10px;
}
.SelectionCountText span:not(:last-child) {
  margin-right: 2px;
}

.CollapseTags {
  max-height: 10vh;
  overflow: hidden;
  position: relative;
}

.CollapseTags::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1));
  display: block;
}

.spinnerContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #000000;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ValueEditor {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ModalSelect {
  flex-grow: 1;
  margin-right: 10px;
}

.ModalButton {
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
}

.ModalButton:hover {
  background-color: white;
}

.DeleteButton {
  background-color: #f44336;
}

.DeleteButton:hover {
  color: white;
  border: 1px solid #f44336;
}

.Instructions {
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;
}

.ScrollContainer {
  overflow-y: auto;
  max-height: 50vh;
  margin-bottom: 20px;
  padding-right: 10px;
}

.ExpandButton {
  background-color: #ddd;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-left: 5px;
  border-radius: 4px;
}

.ExpandButton:hover {
  background-color: #ccc;
}

.EvidenceButton {
  background-color: #ddd;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
  transition: background-color 0.3s ease;
}

.EvidenceButton:hover {
  background-color: #ccc;
}

.DataActionButtons {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.delete-button-datalist {
  background-color: #ffc3c3;
  color: #ff4136;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-left: 5px;
}

.delete-button-datalist:hover {
  background-color: #ffafaf;
}

.ChunkLabelsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.delete-all-button {
  background-color: #f8696c;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  opacity: 0.9;
  margin-top: 5px;
}

.delete-all-button:hover {
  background-color: #ff7875;
  opacity: 1;
}

.failed-tag {
  background-color: #f1c40f;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  padding: 5px 13px;
  justify-content: center;
  align-items: center;
}
.failed-tag svg {
  margin-right: 0.25vw;
}

.modal-content {
  padding: 20px;
}

.toggle-filter-button {
  background-color: #ccc;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-left: 5px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  font-size: 13px;
}

.toggle-filter-button:hover {
  background-color: #ffc3c3;
}

.filtered-count {
  font-size: 13px;
  color: #333;
}

.data-action-button-datalist {
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  margin-left: 5px;
}

.delete-all-button {
  background-color: #f8696c;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  opacity: 0.9;
  margin-top: 5px;
}

.delete-all-button:hover {
  background-color: #ff7875;
  opacity: 1;
}
