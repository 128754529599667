.Message {
  margin-bottom: 12px;
  padding: 20px;
  border-radius: 18px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Message.user,
.Message.assistant {
  align-self: flex-end;
  color: #505e71;
  border-radius: 4px;
}

.Message.assistant {
  background-color: #a2d5c6;
  align-self: flex-start;
  text-align: left;
}

.Message.assistant .Section {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.EvidenceToggle {
  background-color: #e6f4f1;
  color: #333;
  padding: 5px 10px;
  margin-top: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.EvidenceContent {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px;
  margin-top: 5px;
  max-width: 100%;
}

.FinalAnswer {
  background-color: #d9edf7;
  color: #31708f;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #bce8f1;
}

.Message.user {
  background-color: #e6e6e6;
  align-self: flex-end;
  text-align: left;
}

.Message.assistant-typing {
  align-self: flex-start;
  color: #000;
  border-radius: 4px;
}

.Message.assistant-typing {
  font-style: italic;
  opacity: 0.7;
}

.Message:hover {
  transform: scale(1.001);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s;
}

.MessageIcon {
  width: 30px;
  height: 30px;
}

.MessageLogo {
  border-radius: 50%;
  max-height: 3vh;
}

.MessageText {
  flex: 1;
  margin: 0;
}

.MessageText > section {
  background-color: white;
  padding: 10px;
  border-color: 3px;
  margin: 10px;
  border-radius: 4px;
}

.MessageText > section > pre {
  white-space: pre-wrap; /* Respect pre-formatting but wrap text */
  word-wrap: break-word; /* Legacy property for word wrapping */
  overflow-wrap: break-word; /* Preferred property for breaking words */
  max-width: 100%; /* Ensure it doesn't extend beyond its container */
}

.MessageText > section > span {
  color: grey;
}

.MessageText > section > h3 {
  font-weight: bold;
}

.MessageLogo,
.MessageIcon {
  display: block;
}

.MessageIconContainer {
  margin-right: 10px;
}

.Message p {
  font-family: "Helvetica Neue";
  font-size: 0.74vw;
  line-height: 1.5;
  color: #333;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-top: 1vh;
  font-weight: 440;
}

.MessageActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.DeleteMessageButton {
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #333;
}

.Message pre {
  white-space: pre-wrap;
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  margin: 0;
}