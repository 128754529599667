:root {
    --primary-color: #e3e8ee; /* Light Blue */
    --secondary-color: #d1d5db; /* Light Gray */
    --highlight-color: #a2a9b0; /* Subdued Blue */
    --background-color: #ffffff; /* White */
    --text-color: #1f2937; /* Almost Black */
    --border-color: #cbd5e1; /* Soft Border Color */
  }
  
  .DataFilterComponent {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .MetadataFilterContainer {
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 100%;
  }
  
  .MetadataFilterHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 1.5em;
    color: var(--text-color);
  }
  
  .MetadataFilterList {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding: 10px 0;
  }
  
  .MetadataFilterItem {
    padding: 10px 15px;
    margin: 5px 0;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .MetadataFilterItem:hover {
    background-color: var(--highlight-color);
    color: var(--background-color);
  }
  
  .MetadataFilterItem.selected {
    background-color: var(--primary-color);
    color: var(--background-color);
  }
  
  .MetadataFilterControls,
  .MetadataFilterFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .SelectAllButton,
  .ConfirmButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--secondary-color);
    color: var(--background-color);
    transition: background-color 0.3s;
  }
  
  .SelectAllButton:hover,
  .ConfirmButton:hover {
    background-color: var(--highlight-color);
  }
  
  .MetaDataName {
    font-size: 0.7vw;
    font-weight: 700;
  }
  .MetaDataDescription {
    font-size: 0.5vw;
    font-weight: 550;
  }
  
  .LeftSideAligner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .MetadataSearchInput {
    margin-right: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    outline: none;
    margin-top: 1vh;
    font-size: 0.9vw;
    padding: 3px;
  }
  
  .MetadataSearchInput:focus {
    border-color: var(--highlight-color);
  }
  