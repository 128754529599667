.export-options-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.export-options-select-container{
    padding: 10px;   
    display: flex;
    flex-direction: column;

}

.export-options-container h3 {
  margin-bottom: 10px;
  color: #333;
  font-size: 1.2rem;
}

.export-options-container label {
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
}

.export-options-container input[type="checkbox"] {
  margin-right: 10px;
}

.export-options-container select {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
}

.export-options-container button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.export-options-container button:hover {
  background-color: #30917a;
}
