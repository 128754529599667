.quarantine-list-item-parent {
  display: flex;
  flex-direction: column;
}

.review-button-reviewed {
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px;
}

.review-button-reviewed:hover {
  background-color: #298374;
}

.review-button-unreviewed {
  background-color: #a0475f;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px;
}

.review-button-unreviewed:hover {
  background-color: #bd5671;
}
.disabled-element {
  opacity: 0.5; 
  pointer-events: none; 
}
