.menu-container-dropdown {
  display: inline-block;
  margin-right: 10px;
}

.menu-button-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f6f8;
  border: 1px solid #aab7c4;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
  
}

.menu-button-catalog-dropdown {
  background-color: #ffffff;
  border: 0.5px solid #007bff;
  color: #007bff;
  margin: 5px;
}

.menu-button-catalog-dropdown:hover {
  background-color: #f0f9ff;
  border-color: #0056b3;
  color: #0056b3;
}

.menu-button-dropdown:hover {
  background-color: #75c6b9;
  border-color: #ffffff;
}

.TagTextDropdown-dropdown {
  font-size: 0.9rem;
  color: #333;
}

.badge-indicator-dropdown {
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.75rem;
  margin-left: 8px;
}

.button-group-dropdown {
  display: flex;
  align-items: center;
  gap: 5px;
}

.icon-dropdown {
  height: 20px;
  width: 20px;
  color: #aab7c4;
  cursor: pointer;
}

.delete-button-dropdown-dropdown:hover,
.standardize-button-dropdown-dropdown:hover,
.toggle-visibility-button-dropdown:hover {
  color: #333;
}

.drop-down-dropdown {
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 480px;
  overflow-y: auto;
}

.menu-item-dropdown {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  border: none;
  background: none;
  width: 100%;
  text-decoration: none;
  transition: background-color 0.2s;
}

.menu-item-dropdown:hover,
.active-item-dropdown {
  background-color: #f0f0f0;
}

.transition-enter-dropdown {
  transform: scale(0.95);
  opacity: 0;
}

.transition-enter-dropdown-active {
  transform: scale(1);
  opacity: 1;
  transition: opacity 200ms, transform 200ms;
}

.transition-exit-dropdown {
  transform: scale(1);
  opacity: 1;
}

.transition-exit-dropdown-active {
  transform: scale(0.95);
  opacity: 0;
  transition: opacity 150ms, transform 150ms;
}

.ClearFilterButtonUseCase {
  padding: 8px 10px;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  user-select: none;
  background-color: #e9ecef;
  color: #495057;
  margin: 1vw;
}
