.DataCatalogData {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
}

.DataCatalogSummary {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.DataCatalogHeader {
  width: 100%;
  padding: 10px;
}

.ButtonsContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
}

.Button,
.StopTaggingButton,
.ClearFilterButton,
.AddNewTag {
  padding: 8px 10px;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  user-select: none;
  background-color: #e9ecef;
  color: #495057;
}

.Button:hover,
.StopTaggingButton:hover,
.ClearFilterButton:hover,
.AddNewTag:hover {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.Button:active,
.StopTaggingButton:active,
.ClearFilterButton:active,
.AddNewTag:active {
  transform: scale(0.98);
}

.AddNewTag {
  color: #007bff;
  background-color: transparent;
}

.StopTaggingButton {
  background-color: #ffdddd;
  color: #333;
}

.Button:focus,
.StopTaggingButton:focus,
.ClearFilterButton:focus,
.AddNewTag:focus {
  outline: 2px solid #007bff;
  outline-offset: 2px;
}

.DataCatalog-FilterContainer-Child {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}

.FilterActions {
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 10px;
}

.BatchActionButton {
  background-color: #f1c40f;
  color: white;
  border: none;
  border-radius: 4px;
  display: flex;
  font-size: 15px;
  padding: 5px 13px;
  justify-content: center;
  align-items: center;
}

.BatchActionButton svg {
  margin-right: 0.25vw;
}

.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.3s ease;
  z-index: 2000;    
}

.ModalContent {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  min-height: 85vh !important;
  max-height: 90vh !important;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.ModalHeader {
  width: 100%;
  text-align: center;
  padding: 15px;
  font-size: 1.5rem;
  position: relative;
  padding-right: 40px;
  color: #333;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}