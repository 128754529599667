.FilterTableDataList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.DataContainers {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.DataDiscoveryContainer,
.DataSummaryContainer {
  display: flex;
  height: 100%;
  width: 48%;
  flex-direction: column;
}

.LoaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
}

/* Header Styling */
.DataDiscoveryContainer p,
.DataSummaryContainer p {
  font-weight: bold;
  padding: 10px;
  background-color: #f0f0f0; /* Light grey background */
  margin-bottom: 10px; /* Added space below the header */
}
