/* SimplifiedDataList.css */

.SimpleDataList {
  display: flex;
  height: 45vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8;
}

.SimpleDataListHeader {
  width: 100%;
  padding: 10px;
  background-color: #4a90e2;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #3b7dc2;
}

.SimpleDataListContainer {
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
}

.SimpleTagsDataName {
  max-width: 32vw;
  overflow: auto;
  white-space: nowrap;
}

.SimpleDataItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

.SimpleDataItemContainer:hover {
  background-color: #f1f1f1;
}

.SimpleDataItemContent {
  flex-grow: 1;
}

.TagsDataName {
  font-size: 16px;
  color: #333;
}

.previewIcon {
  font-size: 18px;
}
