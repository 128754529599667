.FileUploadContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  height: 450px;
  width: 450px;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;
  padding: 20px;
}
.FileUploadContainer input[type="file"] {
  display: none;
}
.FileUploadLoadingIndicator {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  height: 450px;
  width: 450px;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;
  padding: 20px;
}

.FileUploadControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
