.EvidenceReportsModal {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    border-radius: 4px;
    padding: 10px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.LoadingIndicator {
    align-items: center;
    justify-content: center;
}

.EvidenceReports table {
  width: 100%;
  border-collapse: collapse;
}

.EvidenceReports tbody tr:nth-child(odd) {
  background-color: #f8f9fa;
}
.EvidenceReports tbody > tr > td {
  padding: 5px;
  border: 1px solid #dee2e6;
  text-align: left;
  vertical-align: top;
}

.EvidenceReports thead > tr > td {
  padding: 5px;
  border: 1px solid #dee2e6;
  font-weight: bold;
}

.EvidenceReports tbody .centered {
  text-align: center;
}
