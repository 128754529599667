*,
*::before,
*::after {
  box-sizing: border-box;
}

.UseCaseLibrary {
  height: 100%;
  display: flex;
}

.ButtonBasics {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.24);
  margin-right: 1vw;
  margin-bottom: 1vw;
}

.usecase-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button-container {
  width: 100vw;
  justify-content: flex-end;
}

.usecase-list {
  height: 80vh;
  justify-content: space-between;
  width: 100vw;
  overflow: auto;
}

.row-usecase {
  height: 15vh;
}

.usecase-table {
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.24);
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
}

.addusecase-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 20px;
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.24);
  margin-right: 1vw;
  margin-bottom: 1vw;
}

.activate-api-btn:disabled {
  background-color: #000000;
  cursor: not-allowed;
  position: relative;
  opacity: 0.3;
}

.activate-api-btn:disabled::after {
  content: "Usecase is being created, please wait...";
  position: absolute;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.activate-api-btn:disabled:hover::after {
  opacity: 1;
}

.usecase-cell {
  font-family: "Arial", sans-serif;
  overflow: auto;
  white-space: nowrap;
  padding: 10px 12px;
  border-bottom: 1px solid #e2e8f0;
  max-width: 10vw;
  text-align: left;
  font-size: 15px;
  transition: max-width 0.3s ease;
}

.usecase-cell:hover {
  position: relative;
  z-index: 2;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.activate-api-btn:hover {
  color: #2f8c75;
  text-decoration: none;
}

.name-cell,
.usecase-cell-col,
.dataset-cell,
.document-cell,
.exclude-cell,
.time-validity-cell,
.edit-cell {
  vertical-align: middle;
}

.name-cell {
  width: 200px;
}

.usecase-cell-col {
  width: 150px;
}

.dataset-cell {
  width: 150px;
}

.document-cell {
  width: 150px;
}

.exclude-cell {
  width: 150px;
}

.time-validity-cell {
  width: 150px;
}

.edit-cell {
  width: 100px;
}

.activate-api-btn {
  color: #ffffff;
  padding: 8px 12px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}

.activate-api-btn:hover {
  background-color: white;
}

.delete-button {
  background-color: #ffc3c3;
  color: #ff4136;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #ffafaf;
}

.edit-button-usecase {
  background-color: #add8e6;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-left: 0.3vw;
  margin-right: 0.3vw;
}

.edit-button-usecase:hover {
  background-color: #87ceeb;
}
