.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content input[type="text"] {
  width: 100%;
  padding: 4px;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.modal-content button {
  padding: 10px 15px;
  margin-right: 10px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-content button:hover {
  color: white;
}

.Alignment-container-rule {
  display: flex;
  flex-direction: row;
}

.Info-Text-rule {
  display: flex;
  flex-direction: column;
}

.rules-description {
  font-size: 1em;
  color: #555;
  margin-bottom: 15px;
}

.rules-description,
.total-yes-description {
  margin-bottom: 20px;
  font-size: 1em;
  color: #555;
}

.chart-header {
  font-weight: 550;
  font-size: 0.75vw;
  padding-top: 1vw;
  padding-left: 0.5vw;
}
