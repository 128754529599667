.scheduling-datetime--container {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.scheduling-period--select {
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
  padding: 10px;
}

.scheduling-datetime--datepicker {
  border: 1px solid black;
  border-radius: 3px;
  height: 41px;
}
