.pie-chart-container {
    position: relative;
    padding: 20px; 
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07); 
  }
  
  .pie-chart-center-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #333;
  }