.DocumentList {
  border-right: 1px solid #ccc;
  background-color: #f9f9f9;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 5px;
  max-width: 25vw;
  position: relative;
  flex-direction: column;
  display: flex;
  padding-bottom: 3vh;
}

.HeaderContainer {
  background-color: #e8e8e8;
  color: #505e71;
  padding: 10px;
  font-size: 0.9vw;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.description {
  font-size: 0.9rem;
  color: #666666;
  margin-top: 5px;
  margin-bottom: 15px;
  line-height: 1.4;
}

.DocumentContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  white-space: nowrap;
  justify-content: space-between;
  display: flex;
  color: #333;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.DocumentContainer svg {
  margin-right: 0.25vw;
}
.DocumentContainer > span.evidence-exist {
  color: green;
}
.DocumentContainer > span.evidence-missing {
  color: red;
}

.DocumentContainer:hover {
  transform: scale(1.05);
  font-weight: 500;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}

.DocumentIcon {
  margin-right: 10px;
  color: #333;
}

.ChatBackButton {
  position: absolute;
  bottom: 2vh;
  padding: 10px 20px;
  background-color: #ffffff;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 500;
}

.ChatBackButton:hover {
  color: #ffffff !important;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
}

.DocumentContainer {
  opacity: 0;
  transform: translateX(100px);
  animation: slideIn 0.5s forwards;
  overflow: auto;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.PlaceholderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #888888;
  font-style: italic;
  font-size: 1.2em;
  flex: 0.5;
  padding: 10px;
}

.DocumentListContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 1vh;
  padding: 10px;
}

.relevantDocumentPreview {
  margin-left: 5px;
  padding: 3px 6px;
  font-size: 0.8em;
  background-color: #a2d5c6;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.relevantDocumentPreview:hover {
  background-color: #a2d5c6;
}

.relevantDocumentPreview:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.relevantDocumentPreview .fa-eye {
  margin-right: 3px;
}

.text-generation-controls {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 0.9;
}

.select-box {
  padding: 12px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ccc;
  cursor: pointer;
  outline: none;
  appearance: none;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  flex-grow: 1;
  height: 80%;
  transition: 0.7s;
}

.select-box option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  overflow-x: auto;
  font-size: 0.6vw;
}

.select-box::-webkit-scrollbar {
  width: 5px;
}

.select-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.select-box-wrapper::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #333;
  transition: transform 0.3s ease;
}

.select-box-wrapper.active::after {
  transform: rotate(180deg);
}

.DocumentContainer:active,
.DocumentContainer.selected {
  background-color: #a2d5c6;
  color: white;
}

.select-option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  overflow-x: auto;
  font-size: 0.6vw;
  margin-top: 5px;
  border-radius: 3px;
}

.select-option:hover {
  box-shadow: 0 0 0 3px rgba(40, 164, 144, 0.4);
}

.customTextarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.generateButton {
  background-color: #a2d5c6;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9vw;
  margin-top: 10px;
  transition: background-color 0.3s;
  font-weight: 550;
}

.generateButton:hover {
  background-color: #a2d5c6;
  font-weight: 550;
  color: #505e71;
  transition: 0.4s;
}

.evidence {
  background-color: theme("colors.light");
}
