.chatHistorySidebar {
  background-color: #f9f9f9;
  border-right: 1px solid #ccc;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 5px;
  max-width: 25vw;
  position: relative;
  flex-direction: column;
  display: flex;
  padding-bottom: 3vh;
  height: 100vh;
}

.chatHistoryHeader {
  background-color: #e8e8e8;
  color: #505e71;
  padding: 10px;
  font-size: 0.9vw;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chatHistoryContent {
  padding: 20px;
  overflow: auto;
  flex-grow: 1;
}

.chatItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  padding: 10px 15px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
}

.chatItem:hover,
.chatItem.active {
  background-color: #e8e8e8;
}

.optionsButton {
  border: none;
  cursor: pointer;
  display: flex;
  padding: 5px;
}

.optionsDropdown {
  position: absolute;
  right: 10px;
  top: 100%;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 100;
}

.optionsDropdown button {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
}

.optionsDropdown button:hover {
  background-color: #f0f0f0;
}

.createChatContainer,
.deleteChatContainer {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #fff;
  border-top: 1px solid #ddd;
}

.createChatInput,
.createChatButton {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.createChatButton {
  background-color: #28a745;
  color: white;
  cursor: pointer;
}

.createChatButton:hover {
  background-color: #218838;
}

.loadingChats,
.noChats {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}
