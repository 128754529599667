.access-control {
  font-family: Arial, sans-serif;
  background-color: rgba(231, 237, 244, 1);
  color: #505e71;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.access-control-container {
  font-family: Arial, sans-serif;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  justify-content: center;
}
