.add-rules-container {
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: center;
  gap: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  height: 100%;
}

.conditions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-rules-container h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.add-rules-container h3 {
  margin-bottom: 0.5rem;
}

.rule-creation-section,
.all-rules-section {
  flex: 1;
}

.all-rules-section {
  max-width: 50%;
}

.rule-conditions,
.rule-output {
  background: #f9f9f9;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.rule-conditions > div {
  background: #fff;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0 0.05);
}

select.rules-dropdown {
  width: calc(100% - 2rem);
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
}

.rules-input {
  width: calc(100% - 2rem);
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

button.rules-button {
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0.5rem 0;
}

button.upload-rules-btn {
  width: 100%;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem;
}

button.upload-rules-btn:hover {
  background-color: darken(#2f8c75, 10%);
}

.all-rules-list {
  border-top: 1px solid #eee;
  padding-top: 1rem;
  display: flex;
  gap: 10px;
  overflow: auto;
}

.all-rules-section {
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 8px;
  flex: 1 0 50%;
}

.rule,
.condition {
  background: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 10px;
  align-items: center;
  display: grid;
}

.rule-details {
  margin-bottom: 0.5rem;
}

.rule-conditions span,
.rule-output span {
  font-size: 0.9rem;
  color: #333;
}

.rule-conditions h4,
.rule-output h4 {
  margin: 0;
  font-size: 1rem;
}

.rule-conditions ul,
.rule-output p {
  margin: 5px 0;
  padding: 0;
}

.rule-conditions ul {
  list-style-type: none;
  padding-left: 0;
}

.rule-conditions li,
.rule-output p {
  background: #e6e6e6;
  padding: 5px 10px;
  border-radius: 4px;
}

.remove-rule-button {
  justify-self: end;
  padding: 5px 20px;
  background-color: linear-gradient(#46a68f, #30917a, #1a735e);
}

button.rules-button:hover,
button.upload-rules-btn:hover {
  background-color: #006837;
}

@media (max-width: 768px) {
  .add-rules-container {
    padding: 1rem;
    flex-direction: column;
  }

  .rule {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .rule-creation-section,
  .all-rules-section {
    max-width: 100%;
  }

  input[type="text"],
  button.rules-button,
  button.upload-rules-btn {
    width: 100%;
  }
}
