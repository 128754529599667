.scheduled-tasks-modal {
    font-family: Arial, sans-serif;
}

.styled-table {
    border-collapse: collapse;
    margin: 10px 0 0 0;
    font-size: 0.9em;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead {
    display: table;
    width: 100%; /* Ensure full width */
    background-color: #009879;
    box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.25);
}

.styled-table td {
    padding: 12px 15px;
    text-align: center;
    width: 25%; /* Assign equal width to all columns */
}

.styled-table th {
    padding: 12px 15px;
    text-align: center;
    width: 25%; /* Assign equal width to all columns */
    color: white; /* Set header font color to white */
}

.styled-table tbody {
    display: block;
    max-height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
}

.styled-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.status-pending,
.status-completed,
.status-scheduled,
.status-running {
    font-weight: bold;
}

.status-pending {
    color: #d97642;
}

.status-completed {
    color: #4caf50;
}

.status-scheduled {
    color: #fba82a;
}

.status-running {
    color: #ab2d61;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.ModalHeader {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    padding: 15px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    border-bottom: 1px solid #eee;
}

.refresh-button {
    background-color: #4caf50;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    font-size: 0.9rem;
}

.refresh-button:hover {
    background-color: #45a045;
}