.ChatBoxParent {
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.ChatBoxHeader {
  background-color: #a2d5c6;
  color: white;
  padding: 10px;
  font-size: 1.25rem;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ChatBoxContainer {
  overflow-y: auto;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  height: 100%;
}

.SendMessageButton {
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: #007bff;
  cursor: pointer;
  aspect-ratio: 1/1;
  width: 40px;
  height: 40px;
}

.ChatBoxInput {
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.ChatBoxWritingArea {
  display: flex;
}

.LoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.LoadingSpinner {
  font-size: 24px;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.ResetChatButton {
  background-color: transparent;
  border: none;
  color: #888888 !important;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  padding: 10px;
  margin-left: 5px;
}

.ResetChatButton:hover {
  color: #555555;
}

.ResetChatButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.Placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #888888;
  font-style: italic;
  font-size: 16px;
}
