.menu-container {
  display: flex;
  margin-right: 10px;
  width: 100%;
  padding: 10px;
  position: relative;
  flex-direction: row;
  max-height: 15vh;
  width: 100%;
  justify-content: space-between;
}

.menu-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: #f4f6f8;
  border: 1px solid #aab7c4;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px;
  cursor: pointer;
  width: 80%;
}

.dropdown-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  padding: 10px 10px 20px 20px;
  gap: 0.5vw;
}

.TagHeaderContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 10px;
  overflow-x: auto;
  white-space: nowrap;
}

.menu-button-catalog {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f6f8;
  border: 1px solid #aab7c4;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  width: 80%;
}

.menu-button-catalog:hover {
  background-color: #f0f9ff;
}

.menu-button:hover {
  border-color: #ffffff;
}

.TagTextDropdown {
  font-size: 0.9rem;
  color: #333;
}

.badge-indicator {
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 12px;
}

.icon {
  height: 20px;
  width: 20px;
  color: #aab7c4;
  cursor: pointer;
}

.delete-button-dropdown:hover,
.standardize-button-dropdown:hover,
.toggle-visibility-button:hover {
  color: #333;
}

.drop-down {
  background-color: #fff;
  max-width: 320px;
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  scroll-behavior: smooth;
  max-height: 400px;
}

.transition-enter-active,
.transition-exit-active {
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
}

.menu-item {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  border: none;
  background: none;
  width: 100%;
  text-decoration: none;
  transition: background-color 0.2s;
}

.menu-item:hover,
.active-item {
  background-color: #f0f0f0;
}

.transition-enter {
  transform: scale(0.95);
  opacity: 0;
}

.transition-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: opacity 200ms, transform 200ms;
}

.transition-exit {
  transform: scale(1);
  opacity: 1;
}

.transition-exit-active {
  transform: scale(0.95);
  opacity: 0;
  transition: opacity 150ms, transform 150ms;
}

.TagDescription {
  overflow: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.failed-tag {
}
.failed-tag:hover {
}

.highlighted-tag {
  animation: pulse 2s infinite;
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #298374;
  border-radius: 50%;
  margin-left: 5px; /* Adjust spacing as needed */
}
