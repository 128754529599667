.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  backdrop-filter: blur(5px);
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  height: 80vh;
  width: 90vw;
  z-index: 2000;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  margin-top: 8vh;
}

.highlight {
  font-size: 0.65vw;
  font-weight: 700;
  cursor: pointer;
}

.highlight:hover {
  font-size: 0.6vw;
  font-weight: 800;
}

.modalInnerBody {
  font-family: "Arial", sans-serif;
  font-size: 0.7vw;
  line-height: 1.6em;
  color: #333;
  padding: 5px;
  white-space: pre-wrap;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  text-align: justify;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.closeButton {
  border: none;
  font-size: 1vw;
  cursor: pointer;
  z-index: 10000;
  transition: color 0.3s;
  position: absolute;
  right: 1vw;
  transition: 0.4s;
  top: 1vh;
  font-weight: bold;
  padding: 5px;
}

.closeButton:hover {
  color: #a16f6f;
  transform: scale(1.1);
}

.modalContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.modalInfo {
  padding: 10px;
  top: 0;
  right: 0;
  flex-direction: column;
  width: 20%;
  align-items: flex-start;
}

.evidence-info {
  padding: 4px;
  border-radius: 4px;
  background-color: #f0f0f0;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  display: flex;
  flex-direction: column;
}


.evidence-info button {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 0.5vh;
}

.evidence-info button:hover {
  background-color: #0056b3;
}

.evidence-info button:active {
  background-color: #004085;
}

.redact-button {
  background-color: #8f2a34;
}

.redact-button:hover {
  background-color: #c82333;
}

.redact-button:active {
  background-color: #bd2130;
}

.undo-redact-button {
  background-color: #28a745;
}

.undo-redact-button:hover {
  background-color: #218838;
}

.undo-redact-button:active {
  background-color: #1e7e34;
}
