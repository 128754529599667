/* src/components/AdminDashboard.css */

.admin-dashboard {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }
  
  .admin-dashboard-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
  }
  
  .admin-dashboard-title {
    font-size: 1.5rem;
    color: #343a40;
  }
  
  .admin-dashboard-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .admin-dashboard-table th,
  .admin-dashboard-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .admin-dashboard-table th {
    background-color: #4CAF50;
    color: white;
    text-align: center;
  }
  
  .admin-dashboard-table td {
    text-align: center;
  }
  
  .admin-dashboard-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .admin-dashboard-table tr:hover {
    background-color: #ddd;
  }
  
  .admin-dashboard-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4CAF50;
    color: white;
  }
  
  .loading {
    text-align: center;
    font-size: 20px;
    color: #4CAF50;
  }