.quarantine-list ul {
  list-style-type: none;
  padding: 0;
}

.quarantine-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.quarantine-list-item {
  background-color: rgba(227, 230, 230, 0.5);
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  max-width: 100%;
  overflow: hidden;
}

.quarantine-item-header {
  display: flex;
  align-items: center;
  width: 100%;
}

.details-button {
  background-color: #505e71;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.access-group-selector {
  flex-basis: 30%;
  padding: 16px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  background-color: white;
  color: #505e71;
  height: auto;
  overflow-y: auto;
  cursor: pointer;
}

.access-group-selector option {
  padding: 0.4vw;
  border: #d1d1d1 0.5px solid;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  margin-bottom: 5px;
}

.access-group-selector option:hover {
  background-color: #d9d9d9;
  font-weight: bold;
}

.access-group-selector option:checked {
  background-color: #cecdcd;
  color: #ffffff;
}

@-moz-document url-prefix() {
  .access-group-selector {
    background-image: none;
  }
}

.access-group-selector::-webkit-scrollbar {
  width: 10px;
}

.access-group-selector::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.access-group-selector::-webkit-scrollbar-thumb {
  background: #888;
}

.access-group-selector::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.quarantine-item-details {
  margin-top: 10px;
  padding: 10px;
  background-color: #c0b8b8;
  border-radius: 3px;
  word-wrap: break-word;
  max-width: 50vw;
  align-self: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.previewButton-quarantine {
  background-color: #505e71;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.approval-button {
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.approval-button:hover {
  background-color: #208372;
}

.archive-button {
  background-color: #bd5671;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.archive-button:hover {
  background-color: #a04583;
}

.detail-selector {
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.quarantine-list-container {
  overflow-y: auto;
}

.detail-value {
  margin-top: 10px;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.SearchQuarantineList {
  padding: 5px;
  border-width: 2px;
  margin-right: 1vw;
  width: 30vw;
  padding-left: 10px;
}

.chunk-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px;
  margin-top: 15px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.chunk-sensitive {
  border-left: 5px solid #ff4757;
}

.chunk-title {
  font-size: 1rem;
  color: #333;
  margin-right: auto;
}

.run-chunk-button {
  padding: 5px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.run-chunk-button:hover {
  background-color: #218838;
}

.no-sensitive-info {
  color: #6c757d;
  font-style: italic;
  font-size: 0.9rem;
}

.chunk-entry {
  padding: 5px 0;
  border-top: 1px dashed #e6e6e6;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chunk-alignment {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
  align-items: center;
}

@keyframes pulseEffect {
  0% {
    box-shadow: 0 0 0 0 rgba(74, 144, 226, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(74, 144, 226, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(74, 144, 226, 0);
  }
}

@keyframes scanningAnimation {
  0%,
  100% {
    background-color: #32b09b;
  }
  50% {
    background-color: #326f65;
  }
}

.run-all-button.scanning {
  background-color: #298374;
  animation: scanningAnimation 1.5s infinite;
  color: white;
}

.run-all-button {
  display: flex;
  animation: pulseEffect 2s infinite;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 0 10px;
  background-color: #f2f2f2;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.run-all-button:hover {
  background-color: #a2a8af;
  transition: 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.run-all-button::after {
  content: "Important: Scan all chunks for sensitivity";
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 0.8em;
  white-space: nowrap;
}

.run-all-button:hover::after {
  visibility: visible;
  opacity: 1;
}
