.TeamManagerLoadingIndicator {
  background-color: white;
  position: absolute;
  top: 30%;
  right: 50%;
  border-radius: 4px;
  height: 450px;
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.TeamManagerContainer {
  background-color: white;
  position: absolute;
  top: 30%;
  right: 50%;
  border-radius: 4px;
}
.TeamManagerContainer > h4 {
  margin: auto;
  padding: 10px;
  background-color: #f5f5f5;
  text-align: center;
}

.TeamManagerFooter {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.TeamManagerFooter > button[type="submit"] {
  background-color: rgb(40 164 144 / 1)
}
