.NavBar {
  z-index: 99999;
  width: 100vw;
}

.LogoutButton {
  min-width: 3vw;
  height: auto;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0;
  margin-left: 2vw;
  margin-right: 1vw;
  border-radius: 3px;
  background-color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.Explanation {
  font-size: 0.9em;
  color: #666;
  background-color: #eef2f5;
  padding: 10px;
  border-radius: 4px;
}

.Tooltip {
  position: relative;
  display: inline-block;
}

.Tooltip .TooltipText {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

.Tooltip:hover .TooltipText {
  visibility: visible;
}

.AmazonIcon {
  font-size: 4rem;
  color: #ff9900;
  width: 24px;
  height: 24px;
  margin-right: 1vw;
}

.LocalIcon {
  font-size: 4rem;
  color: #ccc;
  width: 24px;
  height: 24px;
  margin-right: 1vw;
}

.SharePointIcon {
  width: 24px;
  height: 24px;
  color: #0078d4;
  margin-right: 1vw;
}

.NavLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10vw;
}

.NormalPageButton {
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8vw;
  margin: 4px 2px;
  cursor: pointer;
  width: 15vw;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

.NormalPageButton::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: white;
  transition: all 0.3s ease;
}

.CheckAllContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1vh;
  align-items: center;
}

.NormalPageButton:hover::after {
  width: 100%;
  left: 0;
}

.HighlightedPageButton {
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1vw;
  margin: 4px 2px;
  cursor: pointer;
  width: 15vw;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  font-weight: 700;
  white-space: nowrap;
  transition: 0.4s;
}

.HighlightedPageButton:hover::after {
  width: 100%;
  left: 0;
}

.ButtonContainerNavBar {
  width: 80vw;
  justify-content: flex-start;
  display: flex;
  padding-left: 3vw;
}

.VerticalLine {
  width: 2px;
  height: 100%;
}

.NavLogoNavBar {
  padding: 10px;
}

.SaveFileContainer {
  min-width: 5vw;
  white-space: nowrap;
  margin-right: 2vw;
  display: flex;
  padding: 1vw;
  border-radius: 5px;
  transition: all 0.3s ease;
  color: white;
  margin: 0;
}

.SaveFileContainer:hover {
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.2),
    0 3px 5px rgba(0, 0, 0, 0.16);
  transform: translateY(-3px);
  font-weight: 700;
}

.UploadFileContainer {
  white-space: nowrap;
  margin-right: 2vw;
  display: flex;
  padding: 1vw;
  border-radius: 5px;
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.16),
    0 2px 3px rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease;
  color: white;
  margin: 0;
  font-weight: 600;
  padding-left: 2vw;
}
.NavigationBarRightButtonContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 1vw;
  margin-right: 4vw;
}

.notification {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 5px;
  z-index: 1000; /* ensure it's on top */
}

.LogoNavBar {
  display: flex;
  align-items: center;
  max-height: 10vh;
}
.LogoImageNavBar {
  border-radius: 5px;
}

.NavigationBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ButtonContainerNavBar {
  display: flex;
  gap: 20px;
}

.UploadFileContainer {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  transition: 0.4s ease;
}
.LogoutButton:hover {
  background-color: rgb(186, 220, 210);
}

.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.3s ease;
}

.ModalContent {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  min-height: 85vh !important;
  max-height: 90vh !important;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.ModalHeader {
  width: 100%;
  text-align: center;
  padding: 15px;
  font-size: 1.5rem;
  position: relative;
  padding-right: 40px;
  color: #333;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
.ModalOptions {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
  margin-top: 20px;
  position: relative;
}

.TagSelectionView {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: auto;
}

.Option,
.Disabled {
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  cursor: pointer;
  display: flex;
}

.Option:hover {
  background-color: #f0f0f0;
}

.Disabled {
  color: #ccc;
  cursor: not-allowed;
}

.Icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.Close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #333;
  font-size: 1.5rem;
}

.Close:hover {
  color: #555;
}

.ModalContent ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.FileListContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
}

.FileListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  gap: 10px;
}

.BackButton {
  background-color: #46a68f;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 20px;
}

.BackButton:hover {
  background-color: #30917a;
}

.BackButton:active {
  transform: scale(0.95);
}

.hiddenCheckbox {
  display: none;
}

.customCheckbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #eee;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  border: 2px solid #46a68f;
}

.customCheckbox::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  display: none;
}

.hiddenCheckbox:checked + .customCheckbox {
  background-color: #46a68f;
  border-color: #30917a;
}

.hiddenCheckbox:checked + .customCheckbox::after {
  display: block;
}

.FileName {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0;
  transition: background-color 0.3s ease;
}

.FileName,
.FolderButton {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.FileName:hover {
  background-color: #e0e0e0;
}

.FolderContainer {
  background-color: #d5f5e3;
  padding: 25px;
}
.FileContainer {
  background-color: #f0f0f0;
  padding: 25px;
}

.FolderContainer,
.FileContainer {
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.FolderContainer:hover,
.FileContainer:hover {
  background-color: #b2ebf2;
}

.FolderContainer:hover,
.FileContainer:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.FolderButton {
  background-color: #d5f5e3;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 90%;
}

.FolderButton:hover {
  background-color: #b2ebf2;
}

.ViewButton {
  background-color: #46a68f;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.TagCategory:last-child {
  border-bottom: none;
}

.ViewButton:hover {
  background-color: #30917a;
  transform: scale(1.1);
}

.FileDropDownContainer {
  max-height: 35vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.FolderButton {
  background-color: #e0f0f0;
}

.RefreshButton {
  background-color: #46a68f;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 20px;
}

.RefreshButton:hover {
  background-color: #30917a;
}

.RefreshButton:active {
  transform: scale(0.95);
}

.GrayedOut {
  opacity: 0.5;
  pointer-events: none;
}

.headerTitle {
  font-size: 24px;
  color: #4a4a4a;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 2px solid #4a4a4a;
  padding-bottom: 10px;
}

.FileCount {
  margin-left: 10px;
  color: #888;
  font-size: 14px;
  white-space: nowrap;
  width: 10vw;
  text-align: center;
}

.FolderInfo {
  margin-left: auto;
  color: #4a5568;
  font-size: 14px;
  white-space: nowrap;
}

.Highlighted {
  background-color: #ffcccb;
}

.RefreshButton:disabled {
  opacity: 0.3;
  pointer-events: none;
}

.TagSelectionContainer {
  padding: 2vh 1vw;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 30vh;
  max-height: 200vh;
}

.TagCategory {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 2vw;
  border-bottom: 1px solid #e0e0e0;
  margin-top: 1vh;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  border-radius: 2px;
  cursor: pointer;
}

.TagCategory:last-child {
  border-bottom: none;
}

.TagToggleContainer {
  display: flex;
  align-items: center;
  margin-top: 0.5vh;
}

.hiddenCheckbox:checked + .customCheckbox {
  background-color: #46a68f;
  border-color: #30917a;
}

.pii-toggle-label-tag-selection {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  user-select: none;
  padding: 10px;
}

.pii-toggle-slider-tag-selection {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  background-color: #ccc;
  border-radius: 24px;
  transition: background-color 0.2s;
}

.pii-toggle-slider-tag-selection::before {
  content: "";
  position: absolute;
  width: 22px;
  height: 22px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s;
}

.pii-toggle-label-tag-selection input {
  display: none;
}

.pii-toggle-checkbox-tag-selection:checked + .pii-toggle-slider-tag-selection {
  background-color: #46a68f;
}

.pii-toggle-checkbox-tag-selection:checked
  + .pii-toggle-slider-tag-selection::before {
  transform: translateX(26px);
}
.pii-toggle-label-tag-selection span {
  margin-left: 10px;
  font-size: 14px;
}

.TagSelectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 20px;
  border-bottom: 1px solid #ccc;
  background-color: #e8e8e8;
  align-self: center;
  flex-direction: column;
  width: 100%;
}

.ClassificationTagSelectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 20px;
  border-top: 2px #ccc;
  border-bottom: 2px #ccc;
  background-color: #e8e8e8;
  align-self: center;
  flex-direction: column;
  width: 100%;
  font-weight: bold;
}

.selectAllCheckbox:checked {
  color: #46a68f;
}

.selectAllLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  margin-right: 10px;
}

.selectAllCheckbox {
  margin-right: 5px;
  accent-color: #46a68f;
}

.SensitivityCheckDropdownContainer {
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
}

.SensitivityCheckDropdown {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 1rem;
  margin-bottom: 10px;
}

.navbar-button {
  white-space: nowrap;
  display: flex;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
  z-index: 1000;
  top: 0;
}

.dropdown-menu-parent {
  display: flex;
  position: relative;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
  z-index: 1000;
  top: 80px;
  right: 0%;
  border-radius: 4px;
  overflow: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease-out;
  white-space: nowrap;
}

.dropdown-menu .dropdown-item {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.navbar-button:hover + .dropdown-menu,
.dropdown-menu:hover {
  opacity: 1;
  visibility: visible;
}
:root {
  --main-bg-color: #f5f5f5;
  --main-font: "Roboto", sans-serif;
  --primary-color: theme(colors.primary);
  --text-color: #333;
  --text-color-secondary: #777;
  --box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  --hover-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --border-radius: 5px;
  --input-padding: 10px;
}

.navigation-bar {
  font-family: var(--main-font);
  color: var(--text-color);
  background-color: var(--main-bg-color);
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.search-bar input {
  padding: var(--input-padding);
  border: none;
  border-radius: var(--border-radius);
  width: 100%;
  box-shadow: var(--box-shadow);
  font-size: 16px;
  margin-bottom: 20px;
}

.folder-tree,
.file-tree,
.folder-node,
.file-node {
  list-style: none;
  padding-left: 0;
  padding: 15px;
  border-radius: var(--border-radius);
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.3s ease;
}

.folder-node:hover,
.file-node:hover {
  box-shadow: var(--hover-box-shadow);
}

.folder-header .folder-icon {
  color: var(--primary-color);
  font-size: 1.5em;
  margin-right: 5px;
  transition: transform 0.3s ease;
}

.folder-header,
.file-node {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.folder-header > *,
.file-node > * {
  margin-right: 5px;
}

.folder-node.selected,
.file-node.selected {
  border-left: 6px solid #76c7c0;
}

.folder-header input[type="checkbox"],
.file-node input[type="checkbox"] {
  display: none;
}

.folder-header input[type="checkbox"] + label,
.file-node input[type="checkbox"] + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.folder-header input[type="checkbox"] + label:before,
.file-node input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary-color);
  border-radius: 3px;
  background-color: #fff;
}

.folder-header input[type="checkbox"]:checked + label:before,
.file-node input[type="checkbox"]:checked + label:before {
  background-color: var(--primary-color);
}

.folder-header input[type="checkbox"]:checked + label:after,
.file-node input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.folder-node.contained {
  border-left: 6px solid #4caf50;
  padding-left: 14px;
  background-color: #f0fff0;
}

.file-type-icon {
  margin-right: 5px;
}

.file-count {
  margin-left: auto;
  color: var(--text-color-secondary);
}

.file-node .file-icon {
  width: 16px;
}

.file-type-pdf .file-icon {
  color: #e74c3c;
}

.file-type-docx .file-icon,
.file-type-doc .file-icon {
  color: #3498db;
}

.file-type-txt .file-icon {
  color: #f1c40f;
}

@media (max-width: 768px) {
  .file-count {
    display: none;
  }
}

[role="button"],
[role="checkbox"] {
  cursor: pointer;
}

[role="button"]:focus,
[role="checkbox"]:focus {
  outline: 2px solid #3498db;
}

.folder-node:hover,
.file-node:hover {
  background-color: #ecf0f1;
}

.folder-tree {
  transition: max-height 0.3s ease-out;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  width: 40%;
  height: auto;
  justify-content: center;
}

.modal-close-button {
  float: right;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  overflow-y: auto;
  max-height: 70vh;
}

.PreviewButton {
  margin-left: 5px;
  padding: 3px 6px;
  font-size: 0.8em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.PreviewButton:hover {
  background-color: #0056b3;
}

.PreviewButton:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.PreviewButton .fa-eye {
  margin-right: 3px;
}

.DataSourceText {
  font-size: 0.8vw;
}

.UploadFileBtn {
  color: #28a590;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  margin: 20px;
}
.UploadFileBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
